import React from "react";

export default function OwlcamLogo(props) {
  return (
    <svg
      height={props.height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 299.75 58.22"
    >
      <g fill={props.fill} fillRule="evenodd">
        <g>
		<path class="cls-1" d="M10.81,60.91A21.71,21.71,0,0,1,4.22,45a21.71,21.71,0,0,1,6.59-15.91,21.76,21.76,0,0,1,15.94-6.62,21.66,21.66,0,0,1,15.91,6.62A21.66,21.66,0,0,1,49.28,45a21.66,21.66,0,0,1-6.62,15.91,21.66,21.66,0,0,1-15.91,6.62A21.76,21.76,0,0,1,10.81,60.91ZM13.1,31.35A18.6,18.6,0,0,0,7.44,45,18.6,18.6,0,0,0,13.1,58.65a18.6,18.6,0,0,0,13.65,5.66A18.6,18.6,0,0,0,40.4,58.65,18.6,18.6,0,0,0,46.06,45,18.6,18.6,0,0,0,40.4,31.35a18.6,18.6,0,0,0-13.65-5.66A18.6,18.6,0,0,0,13.1,31.35Z" transform="translate(-2.72 -10.83)"/>
		<path class="cls-1" d="M119,24.05V50.74a16.82,16.82,0,0,1-16.76,16.73,16.27,16.27,0,0,1-9-2.69,16.61,16.61,0,0,1-6.07-7,16.66,16.66,0,0,1-6.06,7A16.37,16.37,0,0,1,72,67.47,16.8,16.8,0,0,1,55.29,50.74V24.05h3.22V50.74a13.52,13.52,0,0,0,23.08,9.55,13,13,0,0,0,4-9.55V24.05h3.22V50.74a13.53,13.53,0,0,0,23.09,9.55,13,13,0,0,0,4-9.55V24.05Z" transform="translate(-2.72 -10.83)"/>
		<path class="cls-1" d="M126.36,12.33h3.22V65.89h-3.22Z" transform="translate(-2.72 -10.83)"/>
		<path class="cls-1" d="M158.12,64.34a19.3,19.3,0,0,0,18.72-14.71h3.31a22.52,22.52,0,0,1-22,17.93,21.74,21.74,0,0,1-15.94-6.62A21.71,21.71,0,0,1,135.59,45a21.71,21.71,0,0,1,6.59-15.91,22.49,22.49,0,0,1,30.23-1.49,22,22,0,0,1,7.74,12.8h-3.31a19.29,19.29,0,0,0-38,4.6,19.31,19.31,0,0,0,19.31,19.31Z" transform="translate(-2.72 -10.83)"/>
		<path class="cls-1" d="M226.67,56.54a22.68,22.68,0,0,1-8,8,22.12,22.12,0,0,1-11.25,3A22.5,22.5,0,0,1,184.84,45a21.67,21.67,0,0,1,6.59-15.91,22.45,22.45,0,0,1,31.84,0A21.62,21.62,0,0,1,229.89,45V65.92h-3.22Zm0-11.54a19.31,19.31,0,1,0-38.61,0,19.31,19.31,0,1,0,38.61,0Z" transform="translate(-2.72 -10.83)"/>
		<path class="cls-1" d="M237.22,65.86V39.2a16.1,16.1,0,0,1,4.92-11.84A16,16,0,0,1,254,22.44a16.25,16.25,0,0,1,9.08,2.7,16.49,16.49,0,0,1,6.06,6.94,16.58,16.58,0,0,1,6.07-6.94A16.74,16.74,0,0,1,301,39.2V65.86h-3.22V39.2a13.52,13.52,0,1,0-27,0V65.86h-3.23V39.2A13.49,13.49,0,0,0,254,25.67,13.5,13.5,0,0,0,240.44,39.2V65.86Z" transform="translate(-2.72 -10.83)"/>
        </g>
      </g>
    </svg>
  );
}

import React from "react";
import { FaDownload } from "react-icons/fa";

export default function ImageList(props) {
  return props.images && props.images.length > 0 ? (
    <div className="container">
      <h3 style={{ paddingTop: "30px" }}>Images</h3>
      <div className="row justify-content-center">
        {props.images.map(image => (
          <div
            className="col-lg-5 m-2"
            style={{ border: "0px solid red" }}
            key={"div" + image.fileName}
          >
            <img
              className="fitimage"
              src={image.inline}
              alt={image.fileName}
              key={"img" + image.fileName}
            />
            <br key={"br" + image.fileName} />
            <a
              className="btn btn-success owl-button"
              href={image.attachment}
              download
              key={"a" + image.fileName}
            >
              <FaDownload
                style={{ marginTop: "-4px" }}
                key={"icon" + image.fileName}
              />
              &nbsp;&nbsp;download
            </a>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div>
      <h3 style={{ paddingTop: "20px", paddingBottom: "0px" }}>Images</h3>
      <i>no images in report</i>
    </div>
  );
}

import React from "react";

export default function Footer() {
  return (
    <div>
      <footer className="owl-footer">
        <div className="container">
          <span>© 2019 Owl Cameras Inc. Palo Alto, CA</span>
        </div>
      </footer>
    </div>
  );
}

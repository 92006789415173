import React from "react";
import VideoItem from "./VideoItem";

export default function VideoList(props) {
  return props.videos && props.videos.length > 0 ? (
    <div className="container">
      <h3 style={{ paddingTop: "20px", paddingBottom: "0px" }}>Videos</h3>
      <small>
        (Note: videos may not play in all browsers. Click download to play file
        locally)
      </small>
      <div className="row justify-content-center">
        {props.videos.map(video => (
          <div
            className="col-lg-5"
            style={{ border: "0px solid red" }}
            key={"div" + video.fileName}
          >
            <VideoItem
              inline={video.inline}
              attachment={video.attachment}
              key={"vid" + video.fileName}
            />
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div>
      <h3 style={{ paddingTop: "20px", paddingBottom: "0px" }}>Videos</h3>
      <i>no videos in report</i>
    </div>
  );
}

import React, { Component } from "react";

export default class PinForm extends Component {
  constructor(props) {
    super(props);
    this.state = { value: "", err: null };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value.split(" ").join("") });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.onSubmit(this.state.value);
  }

  render() {
    return (
      <div className="formContent">
        <form onSubmit={this.handleSubmit}>
          <label className="formLabel">Authorization Code</label>
          <br />
          <input
            type="text"
            value={this.state.value}
            onChange={this.handleChange}
            maxLength="9"
            disabled={this.props.busy}
          />
          <br />
          <input
            className="btn btn-success"
            type="submit"
            value="Submit"
            disabled={this.props.busy}
          />
          <div className="pinErrorText">{this.props.error}</div>
          <div hidden={!this.props.busy}>Processing...</div>
        </form>
      </div>
    );
  }
}

import React from "react";
import { FaDownload } from "react-icons/fa";

export default function VideoItem(props) {
  return (
    <div>
      <video width="320" height="240" preload="yes" controls playsInline>
        <source src={props.inline} type="video/mp4" />
      </video>
      <br />
      <a
        className="btn btn-success owl-button"
        href={props.attachment}
        hidden={!props.attachment}
      >
        <FaDownload style={{ marginTop: "-4px" }} />
        &nbsp;&nbsp;download
      </a>
    </div>
  );
}
